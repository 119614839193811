import React, { useState } from "react";
import "./Gallery.module.scss";
import useFetchData from "../../utils/FetchData";
import { useLanguage } from "../../context/Language";
import {
  getGalleryHigjienike,
  getGalleryKozmetike,
  getGalleryUshqimore,
} from "../../utils/api";

const Gallery = () => {
  const { currentLanguage } = useLanguage();
  const { data: ushqimore } = useFetchData(getGalleryUshqimore);
  const { data: higjienike } = useFetchData(getGalleryHigjienike);
  const { data: kozmetike } = useFetchData(getGalleryKozmetike);
  const [selectedImage, setSelectedImage] = useState(-1);
  return (
    <galleryWrapper>
      <textContent>
        <acc>
          <svg
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
              stroke="#DD7D47"
              stroke-width="2"
            />
          </svg>
          {currentLanguage === "en" ? "Gallery" : "Galeria"}
        </acc>
        <h1>{currentLanguage === "en" ? "Gallery" : "Galeria"}</h1>
      </textContent>
      <h2>{currentLanguage === "en" ? "Food" : "Ushqimore"}</h2>
      <br />
      <br />
      <gallery>
        {ushqimore?.data?.map((img, index) => {
          return (
            <GalleryImg
              imageSrc={img.image.url}
              key={index}
              index={index}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          );
        })}
      </gallery>
      <br />
      <br />
      <h2>{currentLanguage === "en" ? "Hygiene" : "Higjienike"}</h2>
      <br />
      <br />
      <gallery>
        <br />
        <br />
        {higjienike?.data?.map((img, index) => {
          return (
            <GalleryImg
              imageSrc={img.image.url}
              key={index}
              index={index}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          );
        })}
      </gallery>
      <br />
      <br />
      <h2>{currentLanguage === "en" ? "Cosmetics" : "Kozmetike"}</h2>
      <br />
      <br />
      <gallery>
        {kozmetike?.data?.map((img, index) => {
          return (
            <GalleryImg
              imageSrc={img.image.url}
              key={index}
              index={index}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          );
        })}
      </gallery>
    </galleryWrapper>
  );
};

export default Gallery;

const GalleryImg = ({ imageSrc, index, selectedImage, setSelectedImage }) => {
  return (
    <>
      <imageWrapper
        onClick={() => {
          if (index !== selectedImage) {
            setSelectedImage(index);
          }
        }}
      >
        <img src={imageSrc} alt="" />
      </imageWrapper>
      <screenImgCover
        style={{
          opacity: index !== selectedImage ? "0" : "1",
          pointerEvents: index === selectedImage ? "unset" : "none",
        }}
        onClick={() => {
          if (index === selectedImage) {
            setSelectedImage(-1);
          }
        }}
      >
        <img src={imageSrc} alt="" />
        <background />
      </screenImgCover>
    </>
  );
};
