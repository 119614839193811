import React, { useRef, useEffect } from "react";
import styles from "./Furniture.module.scss";
import { useLanguage } from "../../context/Language";
import { getBrands, getSuppliers } from "../../utils/api";
import useFetchData from "../../utils/FetchData";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const Furniture = () => {
  const { currentLanguage } = useLanguage();
  const { data } = useFetchData(getBrands);
  const { data: suppliers } = useFetchData(getSuppliers);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    const initializeSwiper = () => {
      if (prevRef.current && nextRef.current) {
        const swiperEl = document.querySelector(`.${styles.swiper}`);
        if (swiperEl && swiperEl.swiper) {
          swiperEl.swiper.params.navigation.prevEl = prevRef.current;
          swiperEl.swiper.params.navigation.nextEl = nextRef.current;
          swiperEl.swiper.navigation.init();
          swiperEl.swiper.navigation.update();
        }
      }
    };

    // Delay initialization to ensure DOM elements are ready
    const timeout = setTimeout(() => {
      initializeSwiper();
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <furnitureWrapper>
      <textContent>
        <h1>{currentLanguage === "en" ? "Suppliers" : "Furnitoret"}</h1>
      </textContent>
      {suppliers && (
        <swiperWrapper>
          <leftArrow ref={prevRef}>
            <svg
              className="leftArrow"
              width="9"
              height="16"
              viewBox="0 0 9 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.822 7.63L1.652.16A.478.478 0 0 0 1.298 0a.478.478 0 0 0-.354.16l-.77.802a.516.516 0 0 0 0 .737L6.223 8 .175 14.297a.516.516 0 0 0 0 .737l.77.801c.102.107.22.16.353.16a.478.478 0 0 0 .354-.16l7.17-7.469a.517.517 0 0 0 0-.738z"
                fill="#131748"
                fillRule="evenodd"
              />
            </svg>
          </leftArrow>

          <Swiper
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            modules={[Navigation, Autoplay]}
            className={styles.swiper}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 6,
              },
            }}
          >
            {suppliers?.data?.map((brand, index) => (
              <SwiperSlide key={index} className={styles.swiperSlide}>
                <img
                  src={brand.image.url}
                  alt=""
                  className={styles.sliderImage}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <rightArrow ref={nextRef}>
            <svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.822 7.63L1.652.16A.478.478 0 0 0 1.298 0a.478.478 0 0 0-.354.16l-.77.802a.516.516 0 0 0 0 .737L6.223 8 .175 14.297a.516.516 0 0 0 0 .737l.77.801c.102.107.22.16.353.16a.478.478 0 0 0 .354-.16l7.17-7.469a.517.517 0 0 0 0-.738z"
                fill="#131748"
                fillRule="evenodd"
              />
            </svg>
          </rightArrow>
        </swiperWrapper>
      )}

      <textContent>
        <h1>{currentLanguage === "en" ? "Brands" : "Brendet"}</h1>
      </textContent>
      <logosWrapper>
        {data?.data?.map((data, index) => (
          <img src={data.image.url} alt="" key={index} />
        ))}
      </logosWrapper>
    </furnitureWrapper>
  );
};

export default Furniture;
